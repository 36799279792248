<template>
    <Modal :title="$t('timeline.form', { title: submit.form.title })" @close="back()">
        <form v-if="submit.id" @submit.prevent="save()" ref="form">
            <template v-for="field in submit.form.fields">
                <FormFieldsModel v-if="field.deleted_at == null || field.value" v-model="field.value" :key="field.id"
                    :field="field" :label="field.label" :required="field.required" :bold="true" class="form-group">
                </FormFieldsModel>
            </template>

            <input type="submit" hidden />

            <MoveToFile v-if="canMoveBetweenFiles" :item="submit"></MoveToFile>
        </form>

        <template #footer>
            <VisibleOnChangeButton :class="{ loading }" ref="observer" :data="submit"
                @click="$refs.form.requestSubmit()"></VisibleOnChangeButton>
        </template>
    </Modal>
</template>

<script>
import axios from 'axios'
import { getAnswerForField } from '~/utils'
import MoveToFile from '~/components/timeline/MoveToFile.vue'
import FormFieldsModel from '~/components/files/FileFormFieldsModel.vue'

export default {
    components: { MoveToFile, FormFieldsModel },

    data() {
        return {
            submit: { form: {} },
            loading: false,
            submit_id: this.$route.params.submit_id,
            canMoveBetweenFiles: this.$store.getters.can('Update form submits'),
        }
    },

    beforeRouteLeave(to, from, next) {
        this.$refs.observer.beforeRouteLeave(to, from, next)
    },

    async mounted() {
        const { data: submit } = await axios.get(`submits/${this.submit_id}`)

        for (let i = 0; i < submit.form.fields.length; i++) {
            const field = submit.form.fields[i]
            field.value = getAnswerForField(field, submit.answers)
        }

        this.submit = submit
    },

    methods: {
        back() {
            if (this.$route.name === 'form_submit.show') {
                this.$router.push({ name: 'forms.show' })
            }

            if (this.$route.name === 'files.form_submit.edit') {
                this.$router.push({ name: 'files.show' })
            }
        },

        async save() {
            this.loading = true
            this.$refs.observer.initialize()

            await axios.patch(`submits/${this.submit.id}`, this.submit)

            window.toast(this.$t('i.update', { i: this.$t('timeline.form', { title: this.submit.form.title }) }))

            const moveToFile = this.submit.moveToFile

            if (moveToFile) {
                await axios.post(`submits/${this.submit.id}/move/${moveToFile.id}`)

                this.$router.push({ name: 'files.show', params: { id: moveToFile.id } })
            } else {
                this.back()
                this.$emit('refresh')
            }
        },
    },
}
</script>
