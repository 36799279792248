<template>
    <div v-if="field.type === 'checkbox'" class="flex-space">
        <label :for="field.id" class="form-label" :class="{ req: required, 'text-bold': bold }">{{ label }}</label>

        <label class="form-checkbox">
            <input type="checkbox" v-model="value" :id="field.id" :true-value="1" :false-value="0"
                :required="required" />
            <i class="form-icon"></i>
        </label>
    </div>

    <div v-else>
        <label :for="field.id" class="form-label" :class="{ req: required, 'text-bold': bold }">{{ label }}</label>

        <textarea v-if="field.type === 'text'" v-model="value" :id="field.id" :placeholder="label" :required="required"
            class="form-input" maxlength="4000" rows="3"></textarea>

        <VSelect v-else-if="field.type === 'single_select'" :options="field.options.choices"
            :multiple="field.options.multi" :inputId="(field.id || '').toString()" :required="required" v-model="value">
        </VSelect>

        <input v-else-if="field.type === 'number'" type="number" class="form-input" :id="field.id"
            v-model.number="value" :step="1 / 10 ** field.options.numdecimals" :placeholder="label"
            :required="required" />

        <template v-else-if="field.type === 'date'">
            <DatePicker v-if="field.options.mode === 'date'" v-model="value" format="yyyy-MM-dd" model-type="yyyy-MM-dd"
                :required="required">
            </DatePicker>
            <DatePicker v-else-if="field.options.mode === 'datetime'" v-model="value" format="yyyy-MM-dd HH:mm"
                model-type="yyyy-MM-dd HH:mm" time-picker-inline :enable-time-picker="true" :required="required">
            </DatePicker>
            <input v-else-if="field.options.mode === 'time'" v-model="value" v-maska="'##:##'" :required="required"
                class="form-input" placeholder="12:34" inputmode="numeric" :id="field.id">
        </template>
    </div>
</template>

<script>
import { vMaska } from 'maska/vue'

export default {
    props: {
        field: Object,
        label: String,
        bold: Boolean,
        required: Boolean,
        modelValue: [String, Number, Array, Date],
    },

    emits: ['update:modelValue'],
    directives: { maska: vMaska },

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
}
</script>
