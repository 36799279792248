<template>
    <Spinner v-if="!issue.issue_number"></Spinner>

    <div v-else class="issues-show">
        <ContextBar backRoute="issues" :title="$t('main.issues')">
            <template #title>
                <div class="flex-start">
                    <h2 @click.prevent="editIssueTitle()" :class="{ 'c-hand': isAuthor }" style="flex: 1">
                        <span>{{ issue.title }}</span>
                    </h2>
                    <h2 class="text-gray">#{{ issue.issue_number }}</h2>
                    <div class="chip chip-lg" :class="[issue.status ? 'chip-success' : 'chip-error']">
                        <i class="fa-solid fa-circle"></i>
                        <span class="ml-2">{{ $tc('issues.issue_status', issue.status) }}</span>
                    </div>
                </div>
            </template>

            <div class="dropdown dropdown-right ml-auto">
                <a tabindex="0" class="btn dropdown-toggle">
                    <i class="fas fa-ellipsis-h fa-fw"></i>
                </a>
                <ul class="menu">
                    <li class="menu-item" v-if="isAuthor">
                        <a href="#" @click.prevent="editIssueTitle()">{{ $t('issues.edit_title') }}</a>
                    </li>
                    <li class="menu-item" v-if="issue.status">
                        <a href="#" @click.prevent="closeIssue()">{{ $t('issues.close_issue') }}</a>
                    </li>
                    <li class="menu-item" v-else>
                        <a href="#" @click.prevent="openIssue()">{{ $t('issues.reopen_issue') }}</a>
                    </li>
                    <li class="menu-item text-error">
                        <a href="#" @click.prevent="deleteIssue()">{{ $t('main.delete') }}</a>
                    </li>
                </ul>
            </div>
        </ContextBar>

        <div class="divider mb-6"></div>

        <div class="columns">
            <div class="column col-8 col-lg-12">
                <div class="issue-timeline mb-4" :class="{ showOnlyComments }">
                    <IssueDescription :issue="issue" @update="fetchData()"></IssueDescription>

                    <div class="box no-shadow">
                        <div class="flex-start mb-6">
                            <h5 class="text-bold m-0 mr-2">{{ $t('issues.activity') }}</h5>
                            <button class="btn btn-link text-dark ml-2" @click="toggleCommentsOnly()">
                                <i class="fa-solid fa-fw" :class="[showOnlyComments ? 'fa-eye-slash' : 'fa-eye']"></i>
                                <span class="ml-2">{{ $t('issues.show_comments_only') }}</span>
                            </button>
                        </div>
                        <IssueTimeline :timeline="issue.timeline" @update="fetchData()"></IssueTimeline>
                    </div>
                </div>

                <TextEditor v-model="comment" :placeholder="$t('issues.write_a_comment')"
                    :buttonText="$t('issues.post_comment')" @save="createComment()" class="mt-4">
                </TextEditor>
            </div>
            <div class="column col-4 col-lg-12">
                <IssueAssignees v-model="issue.assignments" @update="fetchData()"></IssueAssignees>
                <IssueAssociations :associations="issue.associations" @update="fetchData()"></IssueAssociations>
                <IssueAttachments></IssueAttachments>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Spinner from '~/components/Spinner.vue'
import TextEditor from '~/components/htmlform/TextEditor.vue'
import IssueTimeline from '~/components/issues/IssueTimeline.vue'
import IssueAssignees from '~/components/issues/IssueAssignees.vue'
import IssueAssociations from '~/components/issues/IssueAssociations.vue'
import IssueAttachments from '~/components/issues/IssueAttachments.vue'
import IssueDescription from '~/components/issues/IssueDescription.vue'
import transformTimeline from '~/utils/transformIssueTimeline.js'

export default {
    components: {
        Spinner,
        TextEditor,
        IssueTimeline,
        IssueAssignees,
        IssueAssociations,
        IssueAttachments,
        IssueDescription,
    },

    data() {
        return {
            comment: '',
            issue_number: this.$route.params.issue_number,
            issue: { timeline: [], assignments: [], associations: [] },
            showOnlyComments: this.$store.state.issueTimelineShowOnlyComments,
        }
    },

    computed: {
        isAuthor() {
            return this.issue.author.id === this.$store.state.user.id
        }
    },

    async mounted() {
        this.issue = await this.fetchData()
    },

    methods: {
        async fetchData() {
            const { data: issue } = await axios.get(`issues/${this.issue_number}`)

            issue.timeline = transformTimeline(issue.timeline)

            // synchronize timeline
            if (this.issue.issue_number) {
                this.issue.timeline = issue.timeline
            }

            return issue
        },

        toggleCommentsOnly() {
            this.showOnlyComments = !this.showOnlyComments
            this.$store.commit('UPDATE_ISSUE_TIMELINE_SHOW_ONLY_COMMENTS', this.showOnlyComments)
        },

        editIssueTitle() {
            if (!this.isAuthor) return

            this.$swal({
                input: 'text',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#0092c8',
                inputValue: this.issue.title,
                title: this.$t('issues.edit_title'),
                confirmButtonText: this.$t('main.update'),
                cancelButtonText: this.$t('main.cancel'),
                preConfirm: async value => {
                    try {
                        await axios.patch(`issues/${this.issue_number}`, { title: value })
                        this.issue.title = value
                        this.fetchData()
                    } catch ({ response }) {
                        this.$swal.showValidationMessage(response.data?.message);
                    }
                },
            })
        },

        deleteIssue() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(async result => {
                if (!result.value) return

                await axios.delete(`issues/${this.issue_number}`)
                this.$router.push({ name: 'issues' })
                window.toast(this.$t('i.delete', { i: this.issue.title }))
            })
        },

        async createComment() {
            await axios.post(`issues/${this.issue_number}/comments`, { description: this.comment })
            await this.fetchData()
            this.comment = ''
        },

        async closeIssue() {
            if (this.issue.status === 0) return

            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                confirmButtonText: this.$t('issues.close_issue'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(async result => {
                if (!result.value) return

                this.issue.status = 0
                await axios.patch(`issues/${this.issue_number}/close`)
                this.fetchData()
            })
        },

        async openIssue() {
            if (this.issue.status === 1) return

            this.issue.status = 1
            await axios.patch(`issues/${this.issue_number}/open`)
            this.fetchData()
        },
    },
}
</script>